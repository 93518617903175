.block-default {
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-xs);
    --gap: var(--padding-xs);

    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0;//var(--gap);
    align-items: flex-start;

    &.--full {
        grid-column: 1 / 5;
    }

    &.--right {
        grid-column: 3 / 5;
    }

    &.--left {
        grid-column: 1 / 3;
    }

    .--sticky {
        position: sticky;
        top:0;
    }

    .content {
        padding: 0 var(--padding-s);
    }

    .content-full {
        grid-column: 1 / 5;
    }

    .content-left {
        grid-column: 1 / 3;
    }

    .content-right{
        grid-column: 3 / 5;
    }

    .deco {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .image {
        position: relative;
    }

    &:not(.--auto) {
        > .content {
            grid-column: 1 / 3;
        }

        > .image,
        > .deco {
            grid-column: 3 / 5;
        }
    }

    &.--auto > div {
        width: 100%;
        grid-column: span 2;
    }

    ////CONTENT FULL
    &.--full {
        .content-full {
            padding-left: var(--padding-m);
            padding-right: var(--padding-m);
        }
    }

    &.--right {
        padding-right: var(--padding-m);
    }

    &.--left {
        padding-left: var(--padding-m);
    }

    /////BLOCK LINKS
    .block-links {
        margin: var(--padding-s) 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: var(--padding-s);

        &.--block {
            flex-direction: column;
        }

        > .label {
            flex: 1 0 100%;
            @include font-sans();
            font-size: var(--font-size);
        }

        a {
            margin: .1em 0;
        }
    }

    /////BLOCK LINKS
    .block-logos {
        margin: var(--padding-m) 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: var(--padding-s);

        a {
            width: 240px;
            max-width: 40vw;
            height: auto;
            margin: .1em;
        }
    }

    //////IMAGE
    &.--full-height-image > .image[data-scroll-sticky] {
        min-height: 100vh;

        > figure {
            position: static;
        }
    }
}


@media (max-width: $smartphone) {
  .block-default,
  .block-default.--auto,
  .block-default.--full,
  .block-default.--right,
  .block-default.--left,
  .block-default:not(.--auto) {
      grid-column: 1 / 5;
      padding-right: 0;
      padding-left: 0;

      > .content,
      > .content-full {
          padding-left: var(--gap);
          padding-right: var(--gap);
      }

      > div,
      > .content,
      > .content-full,
      > .deco {
          grid-column: 1 / 5;
      }
  }
}

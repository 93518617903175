.block-tracks-grad {
  .content {
    padding-top: var(--padding-l);
    padding-bottom: var(--padding-l);

    @media (max-width: $smartphone) {
      padding-top: var(--padding-xs);
      padding-bottom: var(--padding-xs);
    }
  }
}

.block-tracks-grad__body {
  --font-size-h1: var(--font-size-xlarge);
  --font-size-h2: var(--font-size-mid);
  --font-size-h3: var(--font-size-mid);
  --font-size: var(--font-size-base);
  --color: var(--secondary);
  --padding-v: 0;
  --padding-h: var(--padding-m);
  --max-width: #{(1500px / 16px) * 1rem};
}

.block-tracks-grad__body {
  color: var(--color);

  @include font-sans();
  font-size: var(--font-size);
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--padding-v) var(--padding-m)  var(--padding-v) 0;

  counter-reset: n1 n2;

  > .label {
    font-size: .8em;
  }

  > .label + h2,
  > .label + h3 {
    margin-top: 0;
  }

  h1, h2, h3 {
    letter-spacing: var(--letter-spacing);
    line-height: 1;
    margin-bottom: .2em;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    @include font-sans-bold();
    font-size: var(--font-size-h1);
  }

  h2 {
    @include font-sans-bold();
    font-size: var(--font-size-h2);
  }

  h3 {
    @include font-sans-bold();
    font-size: var(--font-size-h3);
  }

  svg {
    max-width: 100px;
    height: auto;
  }

  p {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    i {
      @include font-sans-italic();
    }

    strong,
    b {
      @include font-sans-bold();
    }
  }

  ul {
    margin: 0 0 1em;
    padding-left: 0;
    list-style: none;

    li {
      --counter-size: #{(30px / 16px) * 1rem};

      position: relative;
      padding-left: calc(var(--counter-size) * 2);
      counter-increment: n1;
      margin: .7em 0;

      &:before {
        position: absolute;
        left:0;
        display: block;
        text-align: right;
        width: var(--counter-size);
        margin-right: var(--padding-s);
        content: counter(n1);
        font-variant-numeric: tabular-nums;
      }
    }
  }

  a:not([class]) {
    @include basic-a();
    @include font-sans-bold();
    text-decoration: underline;
  }

  a {
    color: inherit;
  }

  ///LISTADOS ESPECIALES
  pre {
    line-height: var(--line-height-sans);
    white-space: normal;
    margin: 0;
  }
  pre > ul {
    list-style: none;
    padding: 0;
    @include font-sans();

    > li {
      display: flex;
    }

    b, strong {
      display: inline-block;
      @include font-sans-bold();
      min-width: #{(180px / 16px) * 1rem};
      text-transform: uppercase;
    }

    a:not([class]) {
      @include font-sans();
    }
  }
}

@media (max-width: $smartphone) {
  .block-tracks-grad__body {
    --font-size-h1: var(--font-size-mid);
    --font-size-h2: var(--font-size-mid);
    --font-size-h3: var(--font-size-mid);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-xs);

    padding: var(--padding-v) 0  var(--padding-v) 0;

    pre > ul > li {
      flex-direction: column;
      margin-bottom: .8em;
    }
  }
}

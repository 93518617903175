@mixin link-icon() {
  --pos: 0;
  --scale: 0;
  --font-size: #{(30px / 16px) * 1rem};
  --rec-size: #{(38px / 16px) * 1rem};
  --icon-size: #{(24px / 16px) * 1rem};
  --ease: var(--ease-out-quad);
  --time: .4s;
  --icon: '↘';
  --icon-color: var(--secondary);
  --opacity: 0;


  @include basic-a();
  display: inline-block;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  //height: var(--rec-size);
  padding: .1em calc(var(--rec-size) + .25em) .1em 0;
  border-bottom: 1px solid currentColor;

  @include font-sans-bold();
  font-size: var(--font-size);
  color: inherit;

  &[target="_blank"] {
    --icon: '↗';
  }
  &[download] {
    --icon: '↓';
  }
  &.--to-top {
    --icon: '↑';
  }

  &.--icon {
    --icon: '';

    svg {
      position: absolute;
      @include z-index(2);
      bottom: calc((var(--rec-size) - var(--icon-size))/2);
      right: calc((var(--rec-size) - var(--icon-size))/2);
      width: var(--icon-size);
      height: var(--icon-size);
      fill: var(--icon-color);
      transition: fill var(--time) var(--ease);
    }
  }

  &:before,
  &:after {
    --bg: var(--primary);
    --color: var(--secondary);
    --border: var(--secondary);
    --pos: calc(100% * var(--scale));

    @include pseudo-element-absolute();
    content: var(--icon);
    right: 0;
    bottom:0;
    width: var(--rec-size);
    height: var(--rec-size);

    display: flex;
    justify-content: center;
    align-items: center;

    line-height: 1;
    border-top: 1px solid var(--border);
    border-left: 1px solid var(--border);
    border-right: 1px solid var(--border);
    padding-bottom: 1px;
    background-color: var(--bg);
    color: var(--color);


    transform: translateY(var(--pos));
    transition: transform var(--time) var(--ease);
  }

  &:before {
    --bg: var(--secondary);
    --border: var(--secondary);
    --color: var(--primary);
    --pos: calc((100% * var(--scale)) - 100%);
    opacity: var(--opacity);

    transition:
            transform var(--time) var(--ease),
            opacity var(--time) var(--ease);
  }

  &:hover {
    --scale: 1;
    --icon-color: var(--primary);
    --opacity: 1;
  }

  &.--active,
  &.__link-active {
    --pos: var(--pos-hover);
    pointer-events: none;
  }

  /// TOUCH
  @include isTouch() {

  }
}

.link-icon {
  @include link-icon();
}

@media (max-width: $smartphone) {
  .link-icon {
    --font-size: #{(20px / 16px) * 1rem};
    --rec-size: #{(28px / 16px) * 1rem};
    --icon-size: #{(16px / 16px) * 1rem};
  }
}
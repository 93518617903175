.block-conversation {
  --font-size-title: var(--font-size-mid);

  .title {
    @include font-sans-bold();
    font-size: var(--font-size-title);
    margin: 1em 0 0em;

    letter-spacing: var(--letter-spacing);
    line-height: 1;
  }

  .links {
    list-style: none;
    margin: 2em 0;
    padding: 0;

    @include font-sans();
    font-size: var(--font-size-base);

    a:not([class]) {
      @include basic-a();
      text-decoration: underline;
      color: inherit;
    }

    p {
      margin-top: 0;
    }
  }
}

@media (max-width: $smartphone) {

}
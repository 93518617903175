.block-basic-info ul {
    --font-size: var(--font-size-base);
    --min-width-title: #{(180px / 16px) * 1rem};

    list-style: none;
    margin: 2em 0;
    padding: 0;

    @include font-sans();
    font-size: var(--font-size);

    b, strong {
        display: inline-block;
        @include font-sans-bold();
        text-transform: uppercase;
        min-width: var(--min-width-title);
    }

    a {
        @include basic-a();
        color: currentColor;
        text-decoration: underline;
    }
}


@media (max-width: $smartphone) {}

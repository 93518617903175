:root {
    --y-header: 0;

    --padding-xxl: #{(180px / 16px) * 1rem};
    --padding-xl: #{(120px / 16px) * 1rem};
    --padding-l: #{(90px / 16px) * 1rem};
    --padding-m: #{(60px / 16px) * 1rem};
    --padding-s: #{(30px / 16px) * 1rem};
    --padding-xs: #{(15px / 16px) * 1rem};
    --padding-xxs: #{(8px / 16px) * 1rem};

    --header-height:#{(66px / 16px) * 1rem};
    --header-height-mobile:#{(56px / 16px) * 1rem};

    --stroke: clamp(.5px, #{(2px / 16px) * 1rem}, 1px);
    --padding-horizontal-default: var(--padding-m);
}

@media (max-width: $smartphone) {
    :root {
        --padding-xs: #{(10px / 16px) * 1rem};
    }
}

@function vh($size) {
    @return calc(var(--vh, 1vh) * #{$size});
}

.block-text-grad {
  .content {
    padding-top: var(--padding-l);
    padding-bottom: var(--padding-l);
  }
}

@media (max-width: $smartphone) {
  .block-text-grad {
    .content {
      padding-top: var(--padding-xs);
      padding-bottom: var(--padding-xs);
    }
  }
}
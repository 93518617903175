.widget-home {
  --font-size-big: var(--font-size-xlarge);
  --font-size-small: #{(32px / 16px) * 1rem};
  --font-call: var(--font-size-mid);
  --gap: var(--padding-s);
  --padding-top: calc(var(--header-height) + var(--padding-xs));
  --height-bottom: #{(120px / 16px) * 1rem};

  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding-top: var(--padding-top);

  grid-auto-flow: column;

  > .content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}


.widget-home__title {
  flex: 1;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -.2em 0 0;

  > .big {
    flex: 0 0 auto;
    @include font-sans-bold();
    line-height: 1;
    font-size: var(--font-size-big);
    letter-spacing: var(--letter-spacing);

    &:first-of-type {
      text-align: left;
      margin-left: -.08em;
    }
    &:last-of-type {
      text-align: right;
    }
  }

  > .small {
    flex: 1 0 auto;
    @include font-sans();
    line-height: 1;
    font-size: var(--font-size-small);
    letter-spacing: var(--letter-spacing);
    text-align: center;
    padding-top: 2.55em;
  }
}

.widget-home__advise {
  //flex: 1;
  position: relative;
  color: var(--primary);
  padding-top: var(--gap);
  padding-bottom: var(--gap);
  @include font-sans();
  font-size: var(--font-call);

  &:before {
    @include pseudo-element-absolute();
    top:0;
    left:calc(var(--gap) * -1);
    width: calc(100% + var(--gap) * 2);
    height: 100%;

    z-index: -1;
    background-color: var(--secondary);
  }

  .slider-default {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  a {
    @include basic-a();
    text-decoration: underline;
    color: var(--primary);
  }
}

.widget-home__bottom {
    flex: 0 0 var(--height-bottom);
    height: var(--height-bottom);
    padding: var(--gap) 0;

    display: flex;
    justify-content: space-between;
    justify-self: center;

    .logos {
        gap: var(--gap);
    }

    a {
        flex: 0 0 rem(120px);

        position: relative;
        @include basic-a();

        display: inline;
        height: 100%;
        min-height: 100%;

        svg {
            position: relative;
            display: block;
            height: 100%;
            width: auto;
            margin: 0 auto;
        }
    }
}

@media (max-width: $smartphone) {
  .widget-home {
    --font-size-small: var(--font-size-base);
    --font-call: var(--font-size-base);
    --padding-top: var(--header-height-mobile);
    --gap: var(--padding-xs);
    --height-bottom: auto;

    display: flex;
    flex-direction: column;

    > .deco {
      order: 1;
      width: 100%;
    }
    > .content {
      order: 2;
      width: 100%;
    }
  }

  .widget-home__title {
    align-items: flex-end;
    margin-top: .2em;

    > .big {
      font-size: 12vw;
    }

    > .small {
      font-size: 4vw;
      padding-top: 0;
      padding-bottom: .35em;
    }
  }

  .widget-home__bottom {
    display: block;
    padding-left: 0;
    padding-right: 0;

    .form-newsletter {
      margin: .5em 0 1em;
    }
  }
}

.block-debate {
  --font-size-title: var(--font-size-large);

  .title {
    @include font-sans-bold();
    font-size: var(--font-size-title);
    margin: 2em 0 0em;

    letter-spacing: var(--letter-spacing);
    line-height: 1;
  }

  .block-basic-info {
    margin: 3em 0;
  }

  .body-default {
    padding-top: 0;
  }
}

@media (max-width: $smartphone) {

}
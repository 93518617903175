.form-newsletter {
  --anim: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include font-sans();
  font-size: var(--font-size-base);
  color: var(--secondary);

  &:hover {
    --anim: 1;
  }

  > form {
    position: relative;
    display: flex;
  }

  label {
    --alpha: calc(1 - var(--anim));
    --y: calc(-1em * var(--anim));

    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    opacity: var(--alpha);
    transform: translateY(var(--y));
    transition:
            opacity .1s var(--ease-out-quad),
            transform .2s var(--ease-out-quad);

    > span {
      display: inline-block;
      position: relative;
      border-bottom: 2px solid var(--secondary);
    }
  }

  .textfield {
    flex: 1;
    background: transparent;
    border-bottom: 2px solid var(--secondary);

    &::placeholder {
      color: currentColor;
    }
  }

  .hidden {
    opacity: 0;
    position: absolute;
    left: -9000px;
  }

  button {
    @include basic-a();
    background: transparent;
    border: 0;
  }

  .textfield {
    --alpha: calc(0 + var(--anim));
    --y: calc(1em - 1em * var(--anim));

    opacity: var(--alpha);
    transform: translateY(var(--y));
    transition:
            opacity .1s var(--ease-out-quad),
            transform .2s var(--ease-out-quad);

    &:focus {
      --anim: 1;
    }

    &:focus + label {
      display: none;
    }
  }
}

@media (max-width: $smartphone) {
  .form-newsletter > form {
    width: 100%;
  }
}
.body-default {
    --font-size-h1: var(--font-size-xlarge);
    --font-size-h2: var(--font-size-large);
    --font-size-h3: var(--font-size-mid);
    --font-size: var(--font-size-base);
    --color: var(--secondary);
    --padding-v: 0;
    --padding-h: var(--padding-m);
    --max-width: #{(1500px / 16px) * 1rem};

}

.body-default {
    color: var(--color);

    @include font-sans();
    font-size: var(--font-size);
    max-width: var(--max-width);
    margin: 0 auto;
    padding: var(--padding-v) var(--padding-m)  var(--padding-v) 0;

    > .label {
        font-size: .8em;
    }

    > .label + h2,
    > .label + h3 {
        margin-top: 0;
    }

    em, i {
        @include font-sans-italic();
    }

    strong, b {
        @include font-sans-bold();
    }

    h1, h2, h3 {
        letter-spacing: var(--letter-spacing);
        line-height: 1;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    h1 {
        @include font-sans-bold();
        font-size: var(--font-size-h1);
    }

    h2 {
        @include font-sans-bold();
        font-size: var(--font-size-h2);
    }

    h3 {
        @include font-sans-bold();
        font-size: var(--font-size-h3);
    }

    svg {
        max-width: 100px;
        height: auto;
    }

    p {
        &:first-of-type {
            margin-top: 0;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    ul {
        margin: 2em 0;
        padding-left: 2em;
        overflow: hidden;
    }

    a:not([class]) {
        @include basic-a();
        @include font-sans-bold();
        text-decoration: underline;
    }

    a {
        color: inherit;
    }

    ///LISTADOS ESPECIALES
    pre {
        line-height: var(--line-height-sans);
        white-space: normal;
        margin: 0;
        overflow: hidden;

        &:last-child {
            > ul {
                margin-bottom: 0;
            }
        }
    }
    pre > ul {
        list-style: none;
        padding: 0;
        @include font-sans();

        > li {
            display: flex;
        }

        b, strong {
            display: inline-block;
            @include font-sans-bold();
            min-width: #{(180px / 16px) * 1rem};
            text-transform: uppercase;
        }

        a:not([class]) {
            @include font-sans();
        }
    }
}

@media (max-width: $smartphone) {
  .body-default {
      --font-size-h1: var(--font-size-mid);
      --font-size-h2: var(--font-size-mid);
      --font-size-h3: var(--font-size-mid);
      --font-size: var(--font-size-base);
      --padding-v: var(--padding-xs);
      --padding-h: var(--padding-xs);

      padding: var(--padding-v) 0  var(--padding-v) 0;

      pre > ul > li {
          flex-direction: column;
          margin-bottom: .8em;
      }
  }
}

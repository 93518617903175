.default {
  --gap: var(--padding-xs);

  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  counter-reset: n1 n2;

  > .title {
    position: relative;
    @include z-index(2);
    grid-column: 1 / 3;
    @include font-sans-bold();
    letter-spacing: var(--letter-spacing);
    line-height: 1;
    font-size: var(--font-size-xlarge);
    padding: calc(var(--header-height) + var(--padding-xs)) var(--padding-m) 0 var(--padding-xs);
    margin: 0 0 0 -.06em;
  }
}

@media (max-width: $smartphone) {
  .default > .title {
    grid-column: 1 / 5;
  }
}

.block-workshop {
  --font-size-title: var(--font-size-large);

  .title {
    @include font-sans-bold();
    font-size: var(--font-size-title);
    margin: 2em 0 0em;
    letter-spacing: var(--letter-spacing);
    line-height: 1;
  }

  .block-basic-info {
    margin: 3em 0;
    text-transform: uppercase;

    li {
      display: flex;
    }
  }

  .body-default {
    padding-top: 0;
    padding-bottom: 0;
  }

  .links {
    list-style: none;
    display: flex;
    gap: 10px;
    margin: 3em 0;
    padding: 0;

    li {
      width: 50%;

      > span {
        display: block;
        margin-bottom: .3em;

        @include font-sans();
        font-size: var(--font-size-base);
      }
    }
  }
}

@media (max-width: $smartphone) {
  .block-workshop {

    .block-basic-info {

      li {
        flex-direction: column;
      }
    }
  }
}
.block-file {
  .title {
    margin: 0 0 .5em 0;
    letter-spacing: var(--letter-spacing);
    line-height: 1;
  }

  > .content {
    padding: var(--padding-m) var(--padding-xl) var(--padding-m) var(--padding-s);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  > .image > figure {
    min-height: 100%;
  }
}

@media (max-width: $smartphone) {
  .block-file {
    > .content {
      padding-bottom: var(--padding-s);
    }
  }
}
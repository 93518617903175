.block-news {
  --font-size-title: var(--font-size-large);
  --font-size-info: var(--font-size-base);
  --font-size: var(--font-size-base);
  --padding-top: var(--padding-l);
  --padding-bottom: var(--padding-l);

  @media (max-width: $smartphone) {
    --padding-top: var(--padding-xs);
    --row-gap: var(--padding-s);
  }
}

.block-news {
  .content-full {
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0 0 var(--padding-bottom);
    }
  }
}

.block-news__item {
  @include basic-a();
  text-decoration: none;
  color: var(--secondary);

  > .date {
    @include font-sans-bold();
  }

  > .title {
    @include font-sans-bold();
    font-size: var(--font-size-title);
    line-height: 1;
    letter-spacing: var(--letter-spacing);
  }

  > .summary {
    @include font-sans();
    font-size: var(--font-size);
  }

  &:hover > .title {
    text-decoration: underline;
  }
}


.block-news__paginator {
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;

    @include font-sans-bold();
    font-size: var(--font-size-base);
    line-height: 1;
  }

  a {
    @include basic-a();
    display: block;
    color: var(--secondary);
    padding: 1em;

    &:hover {
      text-decoration: underline;
    }

    &[aria-current="page"] {
      background-color: var(--secondary);
      color: var(--primary);
      pointer-events: none;
    }
  }
}
.block-ul-columns {
  --padding-top: var(--padding-l);
  --padding-bottom: var(--padding-l);
  --row-gap: var(--padding-l);

  @media (max-width: $smartphone) {
    --padding-top: var(--padding-xs);
    --row-gap: var(--padding-s);
  }
}

.block-ul-columns {
  .content-full {
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
  }

  ul {
    list-style: none;
    display: grid;
    margin: 0;
    padding: 0;
    grid-template-columns: 1fr 1fr;
    column-gap: var(--gap);
    row-gap: var(--row-gap);

    > li > .body-default {
      padding: 0;
    }
  }
}
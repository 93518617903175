#Header {
    --width: 100%;
    --height: auto;
    --color: var(--secondary);
    --bg: var(--primary);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-xs);
    --font-size: var(--font-size-base);
    /* ACORDIONS */
    --height-close: 1.125rem;
    --height-open: 240px;
    --duration: .2s;
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: 0 0 var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);

    transform: translate3d(0, var(--y-header), 10px);
    transition: background-color .2s;
}

#Header > nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: var(--padding-xxs);

  > a {
      @include font-sans-bold();
      font-size: var(--font-size);
      text-decoration: none;
      color: inherit;
      margin: 0;
  }

  > .title {
      span {
          display: block;
      }
  }

  button {

  }
}

.header__toogle-menu {
  @include basic-a();
  @include font-sans-bold();

  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0;

  font-size: var(--font-size);
  color: var(--color);
  background-color: transparent;
  border: 0;
  display: none;
}

.header__group {
    overflow: hidden;

    transition-timing-function: var(--ease-in-out-expo);
    transition: height;
    transition-duration: var(--duration);

    height: calc(var(--height-close) + var(--height-open));

    > .title {
        @include font-sans-bold();
        font-size: var(--font-size);
    }

    > ul {
        list-style: none;
        margin: 0;
        padding: var(--padding-xs) 0 0;
        transition: opacity .4s;
    }

    a {
        @include font-sans();
        font-size: var(--font-size);
        text-decoration: none;
        color: var(--primary);
        margin: 0;
    }
}

[aria-expanded="false"] {
  .header__group {
    height: var(--height-close);

    > ul {
      opacity: 0;
      visibility: hidden;
      transition: visibility var(--duration),
      opacity .4s;
    }
  }
}

#Header[aria-expanded="true"] {
  --color: var(--primary);
  --bg: var(--secondary);
}

@media (max-width: $smartphone) {
  #Header {
    --font-size: 24px;
    padding: 0 var(--padding-xs) 0;
  }

  .header__toogle-menu {
    display: flex;
  }

  #Header > nav {
    > a:not(:first-child) {
      display: none;
    }
  }

  .header__group {
    display: none;
  }
}

#Footer {
    --color: var(--secondary);
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-xs);
    --font-size-big: calc(var(--font-size-xlarge) * 1.5);
    --font-size-small: calc(var(--font-size-mid) * 1.5);
    --logos-gap: var(--padding-s);
    --row-gap: var(--padding-m);

    @media (max-width: $smartphone) {
        --logos-gap: var(--padding-xs);
        --row-gap: var(--padding-s);
    }
}

#Footer {
    padding: var(--padding-v) var(--padding-h) 0;
    position: relative;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: var(--padding-xs);
    grid-row-gap: var(--row-gap);

    color: var(--color);

    a {
        @include font-sans-bold();
        color: inherit;
    }
}

.footer__contact {
    grid-column: 3 / 4;

    @include font-sans();
    font-size: var(--font-size-base);
}

.footer__rrss {
    grid-column: 4 / 5;

    display: flex;
    flex-direction: column;

    font-size: var(--font-size-base);
}

.footer__legales {
    grid-column: 4 / 5;

    display: flex;
    flex-direction: column;

    font-size: var(--font-size-base);
}

.footer__logos {
    grid-column: 3 / 4;

    &.--mobile { display: none; }
    &.--desktop { display: block; }

    .logos {
        gap: var(--logos-gap);
        justify-content: flex-start;

        a {
            height: rem(60);

            svg {
                height: 100%;
            }
        }
    }
}

.footer__title {
    grid-column: 1 / 5;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: var(--padding-xxl);

    > .big {
        flex: 0 0 auto;
        @include font-sans-bold();
        line-height: 1;
        font-size: var(--font-size-big);
        letter-spacing: var(--letter-spacing);

        &:first-of-type {
            text-align: left;
            margin-left: -.08em;
        }

        &:last-of-type {
            text-align: right;
        }
    }

    > .small {
        flex: 1 0 auto;
        @include font-sans();
        line-height: 1;
        font-size: var(--font-size-small);
        letter-spacing: var(--letter-spacing);
        text-align: center;
        padding-bottom: .4em;
    }
}

@media (max-width: $smartphone) {
    #Footer {
        --font-size-big: var(--font-size-xlarge);
        --font-size-small: var(--font-size-base);
    }

    .footer__contact {
        grid-column: 1 / 3;
    }

    .footer__rrss {
        grid-column: 3 / 5;
    }

    .footer__logos {
        grid-column: 1 / 4;

        .logos a { height: auto; }

        &.--mobile { display: block; }
        &.--desktop { display: none; }
    }

    .footer__title {
        grid-column: 1 / 5;
        margin-top: var(--padding-xl);

        > .big {
            font-size: 12vw;
        }

        > .small {
            font-size: 4vw;
        }
    }

    .footer__legales {
        grid-column: 1 / 5;
    }
}

.block-next {
  --font-size: var(--font-size-mid);
  --font-size-small: var(--font-size-base);

  grid-column: 1 / 5;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0;

  > .content {
    @include basic-a();
    color: var(--secondary);

    grid-column: 3 / 5;
    padding: 2px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;


    @include font-sans-bold();

    > .small {
      display: block;
      font-size: var(--font-size-small);
    }

    >.title {
      text-decoration: underline;
      font-size: var(--font-size);
    }
  }

  > .deco {
    grid-column: 1 / 3;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}


@media (max-width: $smartphone) {
  .block-next {

  }
}

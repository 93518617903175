.block-paper {
  --font-size-title: var(--font-size-large);

  .title {
    @include font-sans-bold();
    font-size: var(--font-size-title);
    margin: 1em 0 0em;
    letter-spacing: var(--letter-spacing);
    line-height: 1;
  }

  .paper-title {
    /*--font-size: #{(30px / 16px) * 1rem};*/

    @include font-sans-bold();
    /*font-size: var(--font-size);*/
    margin: 2em 0 1em;
    letter-spacing: var(--letter-spacing);
    line-height: 1;
  }

  .links {
    list-style: none;
    margin: 2em 0;
    padding: 0;

    @include font-sans();
    font-size: var(--font-size-base);

    a:not([class]) {
      @include basic-a();
      text-decoration: underline;
      color: inherit;
    }

    p {
      margin-top: 0;
    }
  }
}

@media (max-width: $smartphone) {
  .block-paper {
    .paper-title {
      --font-size: #{(20px / 16px) * 1rem};
    }
  }
}
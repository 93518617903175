.block-title-left {
  margin: var(--padding-l) 0;

  counter-increment: n2;

  .title-left {
    &:before {
      content: counter(n2);
      margin-right: .6em;
    }
  }

  .content {
    padding-top: 0;
    padding-bottom: 0;

    &:first-of-type {
      position: sticky;
      top: rem(60px);
    }
  }
}

@media (max-width: $smartphone) {
  .block-title-left {
    margin: var(--padding-m) 0;
    .content {
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
      overflow: hidden;

      &:first-of-type {
        position: static;
        top: rem(60px);
      }
    }
  }
}
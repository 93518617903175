.block-text-right {
  margin: var(--padding-xs) 0;

  .content,
  .content-full {
    padding-top: var(--padding-l);
  }
}

.block-image + .block-text-right,
.block-text-right + .block-text-right {
  .content,
  .content-full {
    padding-top: 0;
    padding-bottom: 0;
  }

  .body-default {
    h2, h3, h4 {
      &:first-child {
        margin-top: 1em;
      }
    }
  }
}

@media (max-width: $smartphone) {
  .block-text-right {
    .content,
    .content-full {
      padding-top: var(--padding-xs);
      padding-bottom: var(--padding-xs);
    }
  }
}
.widget-contact {
  a {
    display: block;
    @include font-sans-bold();
  }

  .contact, .rrss, .legales {
    margin: var(--padding-m) 0;
  }
}

@mixin gradient-block {
  --gradient: 180deg;
  --distance: calc(100% / -8);
  //background: linear-gradient(var(--gradient), var(--color-grad-1) 0%, var(--color-grad-2) 52%, var(--color-grad-3) 100%);
  background:
          linear-gradient(var(--gradient),
                  var(--color-grad-1) 0%, var(--color-grad-2) 6%, var(--color-grad-3) 12.5%,
                  var(--color-grad-3) 12.5%, var(--color-grad-2) 18.5%, var(--color-grad-1) 25%,
                  var(--color-grad-1) 25%, var(--color-grad-2) 31%, var(--color-grad-3) 37.5%,
                  var(--color-grad-3) 37.5%, var(--color-grad-2) 43.5%, var(--color-grad-1) 50%,
                  var(--color-grad-1) 50%, var(--color-grad-2) 56%, var(--color-grad-3) 62.5%,
                  var(--color-grad-3) 62.5%, var(--color-grad-2) 68.5%, var(--color-grad-1) 75%,
                  var(--color-grad-1) 75%, var(--color-grad-2) 81%, var(--color-grad-3) 87.5%,
                  var(--color-grad-3) 87.5%, var(--color-grad-2) 93.5%, var(--color-grad-1) 100%);
}

.gradient-block {
  --grad: 0;
  --time: 1;
  --delay: 0;
  --timeTrans: calc(var(--time) * 1s);
  --delayTrans: calc(var(--delay) * 1s);

  position: relative;
  display: block;
  padding-top: 100%;
  overflow: hidden;

  &:before {
    --posy: calc(var(--grad) * (-12.5%));

    @include pseudo-element-absolute();
    @include gradient-block();

    top:0;
    left:0;
    width: 100%;
    height: 800%;

    transform: translateY(var(--posy));
    transition: transform var(--timeTrans) var(--ease-in-out-quart) var(--delayTrans);
  }
}

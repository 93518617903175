:root {
    --white: #ffffff;
    --black: rgba(6,8,4,1);
    --grey: #dbdbdb;

    --primary: var(--black);
    --secondary: var(--grey);

    --color-grad-1: rgba(6,8,4,1);
    --color-grad-2: rgba(201,193,187,1);
    --color-grad-3: rgba(219,219,219,1);
    --gradient-top-bottom: linear-gradient(0deg, var(--color-grad-1) 0%, var(--color-grad-2) 52%, var(--color-grad-3) 100%);
    --gradient-bottom-top: linear-gradient(180deg, var(--color-grad-1) 0%, var(--color-grad-2) 52%, var(--color-grad-3) 100%);

    --assertive: #ff00ff;
    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}

.palette-primary {
    --primary: var(--grey);
    --secondary: var(--black);
}

.palette-secondary,
.palette-black {
    --primary: var(--black);
    --secondary: var(--grey);
}

.palette-red {
    --primary: var(--black);
    --secondary: var(--color-error);
}

///VARS
.win-image {
    --bg-color: var(--black);
    
    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }

    background-color: rgba($color: #000000, $alpha: .8)
}

.win-image {
    @include z-index($z-index-windows);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    cursor: pointer;
    
    > img {
        position: absolute;
        @include z-index(1);
        top: 10px;
        left:10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        object-fit: contain;
        object-position: center;
    }

    .fake-button {
        @include basic-a();
        background: transparent;
        outline: 0;
        position: absolute;
        @include z-index(2);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
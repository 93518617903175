.page-new {
  --gap: var(--padding-xs);

  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  > header {
    position: relative;
    @include z-index(2);
    grid-column: 1 / 3;
    padding: calc(var(--header-height) + var(--padding-xs)) var(--padding-m) 0 var(--padding-xs);

    .back {
      @include basic-a();
      @include font-sans();
      font-size: var(--font-size-base);
      color: var(--secondary);
      text-decoration: none;

      &:before {
        content: '←';
      }
    }

    .title {
      @include font-sans-bold();
      letter-spacing: var(--letter-spacing);
      line-height: 1;
      font-size: var(--font-size-large);
      margin: 0;
    }

    > .info {
      @include font-sans-bold();
      font-size: var(--font-size-base);

      strong {
        @include font-sans-bold();
      }
    }
  }
}

@media (max-width: $smartphone) {
  .page-new > header {
    grid-column: 1 / 5;
    padding-right:var(--padding-xs);
  }
}

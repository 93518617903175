.block-image {
  margin: var(--padding-s) 0;

  &.--full {
    grid-column: 1 / 5;
  }

  &.--right {
    grid-column: 3 / 5;
  }

  &.--left {
    grid-column: 1 / 3;
  }
}


@media (max-width: $smartphone) {
  .block-image,
  .block-image.--auto,
  .block-image.--full,
  .block-image.--right,
  .block-image.--left,
  .block-image:not(.--auto) {
    grid-column: 1 / 5;
  }
}

.block-gallery {
  padding-top: var(--padding-s);
  padding-bottom: 0;

  button {
    position: relative;
    @include basic-a();
    padding: 100% 0 0 0;
    border: 0;
    transform: translateZ(1px);
    z-index: 1;
    transition: transform .1s ease-out;

    &:hover {
      transform: scale3d(1.05, 1.05, 1.1) translateZ(2px);
      z-index: 2;
      transform-origin: center;
      transition: transform .2s ease-out;
    }

    img {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media (max-width: $smartphone) {
  .block-gallery {
    .content {
      padding-top: var(--padding-xs);
      padding-bottom: 0;
    }
  }
}
:root {
    --font-sans: 'FKGrotesk-Regular', sans-serif;
    --font-sans-bold: 'FKGrotesk-Medium', sans-serif;
    --font-sans-italic: 'FKGrotesk-ThinItalic', sans-serif;

    //USADAS
    --font-size-xxlarge: #{(360px / 16px) * 1rem};

    --font-size-large: #{(80px / 16px) * 1rem};


    --font-size-xlarge: #{(130px / 16px) * 1rem};
    --font-size-mid: #{(45px / 16px) * 1rem};
    --font-size-base: #{(24px / 16px) * 1rem};
    --font-size-h2: #{(80px / 16px) * 1rem};
    --font-size-h3: #{(40px / 16px) * 1rem};


    --font-size-small: #{(18px / 16px) * 1rem};
    --font-size-xsmall: #{(12px / 16px) * 1rem};

    --line-height-sans: 1.2;
    --letter-spacing: -0.06em;
    --font-size-fluid-base: calc((16/1920) * 100vw);

    font-size: var(--font-size-fluid-base);

    @media (max-width: $smartphone) {
        --font-size-h2: #{(40px / 16px) * 1rem};
        --font-size-h3: #{(24px / 16px) * 1rem};
        --font-size-xlarge: #{(45px / 16px) * 1rem};
        --font-size-large: #{(32px / 16px) * 1rem};
        --font-size-mid: #{(24px / 16px) * 1rem};
        --font-size-base: #{(16px / 16px) * 1rem};

        font-size: 16px;
    }
}
@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-bold($line-height:0) {
    font-family: var(--font-sans-bold);
    font-weight: 500;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-italic($line-height:0) {
    font-family: var(--font-sans-italic);
    font-weight: 200;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

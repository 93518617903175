.slider-default {
  --duration-show: .2s;
  --duration-hide: .0s;
  --delay-show: 0s;
  --delay-hide: 0s;
  --ease-show: var(--ease-out-quad);
  --ease-hide: var(--ease-in-quad);
  --ease-bullet-show: var(--ease-out-quad);
  --ease-bullet-hide: var(--ease-in-quad);
  --duration-bullet-show: .2s;
  --duration-bullet-hide: .2s;
  --button-size: 32px;
  --bullet-size: 18px;
  --color: var(--primary);

  > ul {
    display: grid;
    grid-template-columns: 1fr;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;

      &[aria-hidden="true"] {
        transition:
                opacity var(--duration-hide) var(--ease-hide) var(--delay-hide), visibility var(--duration-hide) var(--ease-hide);
      }

      &[aria-hidden="false"] {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: opacity var(--duration-show) var(--ease-show) var(--delay-show);
      }
    }
  }
}

.slider-default > .bullets {
  --scale: 0;

  list-style: none;
  display: flex;
  justify-content: center;
  gap: var(--bullet-gap);
  margin: 1em 0 0 0;

  &.--disabled {
    display: none;
  }

  > button {
    @include basic-a();
    position: relative;
    width: var(--button-size);
    height: var(--button-size);
    background-color: transparent;

    &:before,
    &:after {
      @include pseudo-element-absolute();
      top: calc((var(--button-size) - var(--button-size)) * .5);
      left: calc((var(--button-size) - var(--button-size)) * .5);
      width: var(--bullet-size);
      height: var(--bullet-size);
      border-radius: 50%;
    }

    &:before {
      border: 1px solid var(--color);
    }

    &:after {
      background-color: var(--color);
      border: 1px solid var(--color);
      transform-origin: center center;
      transform: scale3d(var(--scale), var(--scale), 1);
      transition: transform;
    }

    &[aria-current="false"] {
      --scale: 0;
      &:after {
        transition-timing-function: var(--ease-bullet-hide);
        transition-duration: var(--duration-bullet-hide);
      }
    }

    &[aria-current="true"] {
      --scale: 1;
      pointer-events: none;
      &:after {
        transition-timing-function: var(--ease-bullet-show);
        transition-duration: var(--duration-bullet-show);
      }
    }

    &:hover {
      --scale: .4;
      &:after {
        transition-timing-function: var(--ease-bullet-show);
        transition-duration: var(--duration-bullet-show);
      }
    }


  }
}
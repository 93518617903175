#Sidemenu {
    --color: var(--primary);
    --bg: var(--secondary);
    --font-size-big: var(--font-size-mid);
    --font-size: var(--font-size-base);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
}

#Sidemenu {
    @include z-index($z-index-windows);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 var(--padding-xs) var(--padding-m);

    color: var(--color);
    background-color: var(--bg);

    a {
        @include basic-a();
        text-decoration: none;
        color: var(--color);
    }
}

#Sidemenu > .top-bar {
    position: absolute;
    @include z-index(2);
    top:0;
    left:0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--padding-xs);
    height: var(--header-height-mobile);

    > button {
        font-size: var(--font-size-big);
    }
}

#Sidemenu > .content {
    position: absolute;
    @include z-index(1);
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    padding: calc(var(--header-height-mobile) + var(--padding-xs)) var(--padding-xs);

    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    font-size: var(--font-size);

    > .main-title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin: -.2em 0 0;

        > .big {
            flex: 0 0 auto;
            @include font-sans-bold();
            line-height: 1;
            font-size: 12vw;
            letter-spacing: var(--letter-spacing);

            &:first-of-type {
                text-align: left;
                margin-left: -.08em;
            }
            &:last-of-type {
                text-align: right;
            }
        }

        > .small {
            flex: 1 0 auto;
            @include font-sans();
            line-height: 1;
            font-size: 4vw;
            letter-spacing: var(--letter-spacing);
            text-align: center;
            padding-top: 2.55em;
        }

        > .small {
            padding-top: 0;
            padding-bottom: .35em;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

    }

    a {
        display: block;
        padding: .3em 0;
    }

    > a {
        margin: var(--padding-s) 0;
    }

    > a,
    .title {
        @include font-sans-bold();
        font-size: var(--font-size-big);
    }

    .group {
        a {
            @include font-sans();
        }

        margin: var(--padding-s) 0;
    }
}